import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import { Apps, CodeInput, Connect, DoubleChatBubble, Gpt, Keyword, Rocket } from 'frontend/assets/icons';
import { Loader, PageContent, Panel } from 'frontend/components';
import { NotFound } from 'frontend/features';
import Applications from 'frontend/features/Connect/Applications';
import { NewApplicationForm, UpdateApplicationForm } from 'frontend/features/Connect/Applications/components';
import BotApiKeys from 'frontend/features/Connect/BotApiKeys';
import ChatClients from 'frontend/features/Connect/ChatClients/ChatClients';
import Connector from 'frontend/features/Connect/ChatClients/components/Connector';
import KindlyGPT from 'frontend/features/Connect/KindlyGPTSources/KindlyGPT';
import KindlyGPTSource from 'frontend/features/Connect/KindlyGPTSources/components/KindlyGPTSource/KindlyGPTSource';
import { Plugins } from 'frontend/features/Connect/Plugins';
import PluginConfig from 'frontend/features/Connect/Plugins/components/PluginConfig';
import SubNav from 'frontend/features/SubNav/SubNav';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './sharedStyles.scss';

const LoadableDebugConsole = React.lazy(() => import('frontend/features/DebugConsole/DebugConsole'));

const LazyDebugConsole = (
  <Suspense fallback={<Loader />}>
    <LoadableDebugConsole />
  </Suspense>
);

function FixedLayout() {
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canManageFeatureFlags = hasBotPerms('manage_feature_flags');
  const canConnectBot = hasBotPerms('connect_bot');

  return (
    <PageContent hasFormValidation className={styles.container}>
      <SubNav>
        {canConnectBot && (
          <>
            <SubNav.Item to="plugins" icon={Rocket}>
              Plugins
            </SubNav.Item>
            <SubNav.Item to="chat-clients" icon={DoubleChatBubble}>
              Chat client
            </SubNav.Item>
            <SubNav.Item to="applications" icon={Apps}>
              Applications
            </SubNav.Item>
          </>
        )}
        <SubNav.Item to="kindly-gpt" icon={Gpt}>
          Kindly GPT
        </SubNav.Item>
        {canConnectBot && (
          <>
            {canManageFeatureFlags && (
              <SubNav.Item to="integrations" icon={Connect} className="kindlyAdminManager">
                Integrations
              </SubNav.Item>
            )}
            <SubNav.Item to="api-keys" icon={Keyword}>
              API keys
            </SubNav.Item>
            <SubNav.Item to="debug-console" icon={CodeInput}>
              Debug console
            </SubNav.Item>
          </>
        )}
      </SubNav>

      <Outlet />
    </PageContent>
  );
}

export default function ConnectRoutes() {
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canConnectBot = hasBotPerms('connect_bot');
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={canConnectBot ? 'plugins' : 'kindly-gpt'} />} />

      <Route element={<FixedLayout />}>
        {canConnectBot && (
          <>
            <Route path="plugins" element={<Plugins />} />
            <Route path="plugins/:pluginId" element={<PluginConfig />} />

            <Route path="chat-clients" element={<ChatClients />} />
            <Route path="chat-clients/:connectorId" element={<Connector />} />

            <Route path="applications" element={<Applications />} />
            <Route path="applications/new" element={<NewApplicationForm />} />
            <Route path="applications/:appId" element={<UpdateApplicationForm />} />
            <Route path="integrations" element={<Panel>Coming soon!</Panel>} />

            <Route path="api-keys" element={<BotApiKeys />} />

            <Route path="debug-console" element={LazyDebugConsole} />
          </>
        )}

        <Route path="kindly-gpt" element={<KindlyGPT />} />
        <Route path="kindly-gpt/:sourceId" element={<KindlyGPTSource />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
